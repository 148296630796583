import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/legacy/image";
import Link from "next/link";
import Balancer from "react-wrap-balancer";

const ArticleCard = ({ article, imageContainerClassName = "" }) => {
  const image = article.image?.[0];

  // Fetch the article category
  const category = article.articleCategories?.[0];

  const href = `/${article.uri}`;

  return (
    (<div
      className={clsx(
        "isolate h-full overflow-hidden rounded-2xl bg-zinc-100 @container"
      )}
    >
      <Link
        href={href}
        prefetch={false}
        className={clsx(
          "relative aspect-square bg-zinc-200",
          imageContainerClassName
        )}>

        {!!image && (
          <Image
            src={image.url}
            layout="fill"
            objectFit="cover"
            alt={image.title}
            className="transition-transform ease-out hover:scale-[1.025]"
            loader={cloudflareLoader}
          />
        )}

      </Link>
      <div className={clsx("flex flex-col space-y-2 p-6 lg:p-8")}>
        {!!category && (
          <Link
            href={`/life/category/${category.slug}`}
            prefetch={false}
            className={clsx(
              "font-body text-sm text-zinc-800 transition ease-out hover:text-zinc-950"
            )}>

            {category.title}

          </Link>
        )}
        <Balancer
          className={clsx("heading-sm--spatial lg:heading-xs--spatial")}
        >
          <Link
            href={href}
            prefetch={false}
            className={clsx("transition ease-out hover:opacity-75")}>

            {article.title}

          </Link>
        </Balancer>
        <div className={clsx("body-text-sm line-clamp-4 @xl:max-w-[66.666%]")}>
          {article.summary}
        </div>
      </div>
    </div>)
  );
};

export default ArticleCard;
