import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/legacy/image";
import Link from "next/link";
import Balancer from "react-wrap-balancer";
import Target from "./Target";
import Button from "@components/Button";
import { useMediaQuery } from "@react-hook/media-query";

const GuestFeatureModule = ({
  heading,
  subheading,
  image,
  imageLocation,
  buttonLabel,
  href = undefined,
  target = undefined,
  hasCta,
}) => {
  const _Wrapper = ({ children, className }) => {
    if (!hasCta) {
      return <div className={className}>{children}</div>;
    }

    if (!!href) {
      return (
        (<Link href={href} prefetch={false} className={className}>
          {children}
        </Link>)
      );
    }

    if (!!target) {
      return (
        <Target target={target} className={className}>
          {children}
        </Target>
      );
    }

    return <div className={className}>{children}</div>;
  };

  // The image might be an array with a single element, or just an object
  const targetImage =
    Array.isArray(image) && image.length > 0 ? image[0] : image;

  const isMobile = useMediaQuery("(max-width: 590px)");

  return (
    <div
      className={clsx(
        "FeatureModule FeatureModuleBlock",
        "@container 2xl:container",
        "px-6 lg:px-12"
      )}
    >
      <div
        className={clsx(
          "isolate flex flex-col overflow-hidden rounded-2xl bg-white @xl:flex-row"
        )}
      >
        {!!targetImage && (imageLocation === "imageLeft" || isMobile) && (
          <div
            className={clsx(
              "@xl:w-1/2",
              "relative block aspect-square overflow-hidden"
            )}
          >
            <Image
              src={targetImage.url}
              layout="fill"
              objectFit="cover"
              alt={targetImage.title}
              className="rounded-[20px]"
              loader={cloudflareLoader}
            />
          </div>
        )}
        <div className={clsx("flex items-center justify-center @xl:w-1/2")}>
          <div className={clsx("p-6 lg:p-12")}>
            <Balancer className={clsx("heading-lg--spatial")}>
              {heading}
            </Balancer>
            <div className={clsx("body-text-sm my-6")}>{subheading}</div>
            {hasCta && (
              <_Wrapper className="">
                <Button
                  theme="light"
                  className={clsx(isMobile ? "w-full" : "w-auto")}
                >
                  {buttonLabel}
                </Button>
              </_Wrapper>
            )}
          </div>
        </div>
        {!!targetImage && imageLocation === "imageRight" && !isMobile && (
          <div
            className={clsx(
              "@xl:w-1/2",
              "relative block aspect-square overflow-hidden"
            )}
          >
            <Image
              src={targetImage.url}
              layout="fill"
              objectFit="cover"
              alt={targetImage.title}
              className="rounded-[20px]"
              loader={cloudflareLoader}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GuestFeatureModule;
