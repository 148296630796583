import Spinner from "@/components/Spinner";
import { useHeaderContext } from "@/contexts/HeaderContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import cloudflareLoader from "@/lib/cloudflare-images";
import MainHeading from "@/src/components/MainHeading";
import { useHomeHeroContext } from "@/src/contexts/HomeHeroContext";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import dayjs from "dayjs";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const HomepageHero = observer(() => {
  const { setIsHeroScrolledPast } = useHeaderContext();
  const [ref, inView] = useInView({
    threshold: 0,
    rootMargin: "-1px 0px 0px 0px",
  });

  const imageUrl =
    "https://files.mob-cdn.co.uk/homepage/sausage-gochujang-rigatoni.jpg";

  useEffect(() => {
    setIsHeroScrolledPast(!inView);

    return () => {
      setIsHeroScrolledPast(true);
    };
  }, [inView, setIsHeroScrolledPast]);

  return (
    <div ref={ref} className={clsx("bg-white text-white")}>
      <div
        className={clsx("relative transform-gpu overflow-hidden bg-zinc-950")}
      >
        <div
          className={clsx(
            "relative z-10 space-y-4 px-6 py-24 sm:space-y-6 md:px-12 lg:py-24"
          )}
        >
          <_Heading />
          <_Form />
          <_Links />
        </div>
        <div className={clsx("absolute inset-0 z-0")}>
          <Image
            src={imageUrl}
            alt="Hero Background"
            layout="fill"
            objectFit="cover"
            loader={cloudflareLoader}
            priority
            sizes="100vw"
          />
          <div
            className={clsx("absolute inset-0 z-10 bg-zinc-900 bg-opacity-25")}
          />
        </div>
      </div>
    </div>
  );
});

const _Heading = observer(() => {
  const { currentUser, isLoggedIn, isFetching } = useCurrentUser();
  const { userHasSeenIntro, setUserHasSeenIntro } = useHomeHeroContext();
  const [showPersonalized, setShowPersonalized] = useState(false);

  const setPersonalized = useCallback(() => {
    setShowPersonalized(true);
    setUserHasSeenIntro(true);
  }, [setUserHasSeenIntro]);

  useEffect(() => {
    if (isLoggedIn && !isFetching) {
      if (userHasSeenIntro) {
        setPersonalized();
      } else {
        const timer = setTimeout(setPersonalized, 1500);
        return () => clearTimeout(timer);
      }
    } else if (!isLoggedIn && !isFetching) {
      setShowPersonalized(false);
    }
  }, [isLoggedIn, isFetching, setPersonalized, userHasSeenIntro]);

  return (
    <AnimatePresence exitBeforeEnter>
      {showPersonalized && isLoggedIn ? (
        <LoggedInHeading key="logged-in" currentUser={currentUser} />
      ) : (
        <LoggedOutHeading key="logged-out" />
      )}
    </AnimatePresence>
  );
});

const LoggedInHeading = ({ currentUser }) => {
  const currentTime = dayjs();
  const timeOfDay = currentTime.hour() >= 12 ? "tonight" : "today";

  return (
    <div>
      <MainHeading>
        Hi, {currentUser?.firstName}. What would you like to cook {timeOfDay}?
      </MainHeading>
    </div>
  );
};

const LoggedOutHeading = () => {
  return (
    <div className={clsx("text-balance text-left")}>
      <MainHeading>
        Recipe inspiration and meal&nbsp;planning, all in one place
      </MainHeading>
    </div>
  );
};

const _Form = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const searchQuery = formData.get("query");

    if (searchQuery) {
      setIsLoading(true);
      const encodedQuery = encodeURIComponent(searchQuery.toString());
      router.push(`/search?q=${encodedQuery}`);
    }
  };

  return (
    <form className={clsx("relative w-full max-w-3xl")} onSubmit={handleSubmit}>
      <input
        type="text"
        name="query"
        placeholder="Search by dish, ingredient or cuisine"
        className={clsx(
          "h-component-lg block w-full rounded-full bg-white px-5 font-body text-sm text-zinc-950 sm:h-12 sm:px-6 sm:text-base md:h-12 md:px-8 lg:h-16 lg:text-lg"
        )}
        disabled={isLoading}
      />
      <button
        type="submit"
        className={clsx(
          "w-component-lg h-component-lg absolute right-0 top-0 rounded-full sm:size-12 lg:size-16"
        )}
        disabled={isLoading}
      >
        <div
          className={clsx(
            "absolute inset-1 flex items-center justify-center rounded-full bg-zinc-950 md:inset-1.5",
            { "opacity-70": isLoading }
          )}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <ArrowRightIcon className={clsx("size-4 sm:size-5 lg:size-6")} />
          )}
        </div>
      </button>
    </form>
  );
};

const _Links = () => {
  return (
    <div className={clsx("flex items-center gap-4 font-body text-sm")}>
      <_Link href="/search?section=Recipes">All recipes</_Link>
      <_Link href="/meal-plans">All meal plans</_Link>
    </div>
  );
};

const _Link = ({ href, children }) => {
  return (
    (<Link
      href={href}
      className={clsx(
        "h-component flex items-center justify-center rounded-full bg-white/20 px-5 backdrop-blur-lg transition ease-out hover:bg-white/30"
      )}>

      {children}

    </Link>)
  );
};

export default HomepageHero;
